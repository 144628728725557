<template>
  <v-chip-group v-if="tags.firstDepth || tags[0]">
    <v-chip
      v-for="(tag, idx) in [tags[0].firstDepth, tags[0].secondDepth, tags[0].thirdDepth]"
      v-show="tag"
      :key="idx"
      label
      :color="colorList[idx]"
      :small="small"
      light
    >
      {{ tag }}
    </v-chip>
  </v-chip-group>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@vue/composition-api'

export default defineComponent({
  props: { tags: [Array, Object], small: Boolean },

  setup() {
    const state = reactive({
      colorList: ['#FFD600', '#FFE500', '#FFF4BB'],
    })

    return {
      ...toRefs(state),
    }
  },
})
</script>
