<template>
  <v-btn depressed light :block="block" :large="large" :color="getTagColorAndName.color" :outlined="getTagColorAndName.outlined" @click="$emit('click')">
    <b v-if="bold">
      {{ getTagColorAndName.name }}
    </b>
    <span v-else>
      {{ getTagColorAndName.name }}
    </span>
  </v-btn>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  props: { tag: Object, block: Boolean, large: Boolean, bold: Boolean },

  setup(props) {
    const getTagColorAndName = computed(() => {
      if (props.tag.show == 'pending' && props.tag.reply == false)
        return {
          color: '#ffd600',
          outlined: true,
          name: '대기중',
        }
      else if (props.tag.show == 'show' && props.tag.reply == false)
        return {
          color: '#ffd600',
          name: '답변중',
        }
      else
        return {
          color: 'grey lighten-2',
          name: '답변완료',
        }
    })

    return {
      getTagColorAndName,
    }
  },
})
</script>
